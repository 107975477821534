<div class='header'>
  <div class='item'>
    <fol-img [src]='src' [title]='brandImageName' *ngIf="isThemingSet"/>
  </div>
  <div class='item center'>
    <fol-breadcrumb-trail/>
  </div>
  <div class='item'>
  </div>
</div>
