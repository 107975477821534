import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppConfig, Config, VERSION_TOKEN } from './app/app.config';
import packageJson from 'package.json';

if (environment.production) {
  enableProdMode();
}

const loadConfig = (): Promise<void | Config> => {
  return new Promise<Config>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', './assets/config.json');

    xhr.addEventListener('readystatechange', () => {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
        resolve(JSON.parse(xhr.responseText.replace(/\n/g, '')));
      } else if (xhr.readyState === XMLHttpRequest.DONE) {
        reject();
      }
    });

    xhr.send(null);
  }).catch(err => console.error('error while parsing config', err));
};

loadConfig().then(config => {
  if (config) {
    platformBrowserDynamic([
      {
        provide: AppConfig,
        useValue: {
          baseWsURL: (): string => config.url,
          apikey: (): string => config.apikey,
          authUrl: (): string => config.auth?.url,
          authApikey: (): string => config.auth?.apikey
        }
      },
      {
        provide: VERSION_TOKEN,
        useValue: packageJson.version
      }
    ])
      .bootstrapModule(AppModule, { preserveWhitespaces: true })
      .catch(err => console.log(err));
  }
});
