import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateComponent } from './create/create.component';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { GraphicModule } from '../../graphic/graphic.module';
import { FinalComponent } from './final/final.component';
import { TextInputComponent } from 'ngx-mobilite-material';

@NgModule({
  declarations: [CreateComponent, LoginComponent, FinalComponent],
  imports: [CommonModule, SharedModule, GraphicModule, TextInputComponent],
  exports: [RouterModule]
})
export class UserModule {}
