import { Directive, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { TakeUntil } from '../take-until.component';
import { takeUntil } from 'rxjs';
import { DirectiveManagementService } from './directive-management.service';

@Directive({
  selector: '[folToUppercase]'
})
export class ToUppercaseDirective extends TakeUntil implements OnInit {
  constructor(
    private readonly ngControl: NgControl,
    private readonly directiveManagementService: DirectiveManagementService
  ) {
    super();
  }

  ngOnInit(): void {
    this.ngControl.control?.valueChanges.pipe(takeUntil(this.destroy)).subscribe(() => {
      this.directiveManagementService.addTreatment(this.ngControl.control, (value: string) =>
        (value || '').toUpperCase()
      );
    });
  }
}
