import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { ApplicationDataState, ImmutableState } from './application.model';
import { initialImmutableValue, initialValue, productionInitialValue } from './application-store.init';
import { EnvironmentService } from '../helpers/environment.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationStore {
  private readonly state = new BehaviorSubject<ApplicationDataState>(
    this.environmentService.getEnvironment().production ? productionInitialValue : initialValue
  );

  private readonly immutableState = new BehaviorSubject<ImmutableState>(initialImmutableValue);

  get value(): ApplicationDataState {
    return this.state.value;
  }

  constructor(private readonly environmentService: EnvironmentService) {}

  instant<F extends keyof ApplicationDataState>(field: F): ApplicationDataState[F] {
    return this.state.value[field];
  }

  setImmutable(immutableState: ImmutableState): void {
    this.immutableState.next(immutableState);
  }

  instantImmutable<F extends keyof ImmutableState>(field: F): ImmutableState[F] {
    return this.immutableState.value[field];
  }

  query<F extends keyof ApplicationDataState>(field: F): Observable<ApplicationDataState[F]> {
    return this.state.pipe(map(state => state[field]));
  }

  update<F extends keyof ApplicationDataState>(field: F, data: ApplicationDataState[F]): void {
    const state = { ...this.state.value };
    state[field] =
      typeof state[field] === 'object'
        ? ({ ...(state[field] as object), ...(data as object) } as ApplicationDataState[F])
        : data;
    this.state.next(state);
  }

  emptyAllStore(): void {
    Object.keys(this.state.value).forEach(field => {
      this.state.value[field as keyof ApplicationDataState] = null;
    });
  }

  reset(value: ApplicationDataState | null = null): void {
    this.state.next(value || (this.environmentService.getEnvironment().production ? {} : initialValue));
  }
}
