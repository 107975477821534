import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isDayMonthYear, isMonthYear } from 'ngx-mobilite-material';

export type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType
  : never;

export type ToForm<T> = {
  [key in keyof T]: T[key] extends readonly unknown[]
    ? FormArray<FormControl<ArrayElement<T[key]> | null>>
    : T[key] extends object
    ? FormControl<T[key] | null> | AbstractControl<T[key]>
    : FormControl<T[key] | null>;
};

export const toValidator = (predicate: (_: AbstractControl) => boolean, errorKey: string): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    return predicate(control) ? null : { [errorKey]: true };
  };
};

export const toGroupValidator = (validator: (_: AbstractControl) => boolean, errorKey: string): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const error = validator(control) ? null : { [errorKey]: true };
    dispatchChildrenErrors(control, error, errorKey);
    return error;
  };
};

export const dispatchChildrenErrors = (
  control: AbstractControl,
  error: ValidationErrors | null,
  errorKey: string
): void => {
  const controls = (control as FormGroup).controls;
  Object.keys(controls).forEach(key => {
    removeError(controls[key], errorKey);
    addErrors(controls[key], error);
  });
};

export const removeError = (control: AbstractControl, errorKey: string): void => {
  const errors = control.errors;
  if (errors && control.hasError(errorKey)) {
    const errorsWithoutErrorKey = Object.keys(errors).reduce((acc, key) => {
      if (key === errorKey) {
        return { ...acc };
      }
      return { ...acc, [key]: errors[key] };
    }, {});
    setError(control, errorsWithoutErrorKey);
  }
};

export const addErrors = (control: AbstractControl, errors: ValidationErrors | null): void => {
  if (errors !== null) {
    const errorsWithErrorKey = { ...control.errors, ...errors };
    setError(control, errorsWithErrorKey);
  }
};

const setError = (control: AbstractControl, errors: ValidationErrors | null): void => {
  control.setErrors(errors !== null && Object.keys(errors).length > 0 ? errors : null);
};

export const removeControlIfPresent = (formGroup: FormGroup, controlName: string): void => {
  if (formGroup.contains(controlName)) {
    formGroup.removeControl(controlName);
  }
};

export const replaceFieldValues = (formGroup: FormGroup, data: unknown, parentKey = ''): void => {
  if (typeof data === 'object' && data) {
    Object.keys(data).forEach(key => {
      const value = data[key as keyof typeof data];
      if (typeof value === 'object' && !isFormPrimitive(value)) {
        replaceFieldValues(formGroup, value, parentKey ? `${parentKey}.${key}` : key);
      } else {
        if (value) {
          if (!formGroup.get(parentKey ? `${parentKey}.${key}` : key)?.value) {
            formGroup.get(parentKey ? `${parentKey}.${key}` : key)?.patchValue(value);
          }
        }
      }
    });
  }
};

export const isFormPrimitive = (x: unknown): boolean => {
  return isMonthYear(x) || isDayMonthYear(x);
};

export const getDebounceTimeIfIsRecap = (isRecap: boolean): number => {
  return isRecap ? 1000 : 100;
};
