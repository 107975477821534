import { Injectable } from '@angular/core';
import { HttpRequest, HttpEvent, HttpHeaders, HttpInterceptor, HttpHandler } from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { AppConfig } from '../../../app.config';
import { AuthenticationService } from '../../application/authentication.service';

@Injectable()
export class GatewayInterceptor implements HttpInterceptor {
  constructor(private readonly appConfig: AppConfig, private readonly authenticationService: AuthenticationService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.authenticationService.isAuthenticated$.pipe(
      switchMap(isAuthenticated => {
        if (!isAuthenticated || !this.hasGatewayEnabled() || this.shouldNotUseGateway(req.url)) {
          // Not authenticated or no gateway
          return next.handle(req);
        } else {
          const headers = new HttpHeaders({
            apikey: this.appConfig.apikey()
          });
          return next.handle(
            req.clone({
              headers
            })
          );
        }
      })
    );
  }

  private hasGatewayEnabled(): boolean {
    return !!this.appConfig.apikey();
  }

  private shouldNotUseGateway(url: string): boolean {
    return !url.startsWith(this.appConfig.baseWsURL()) && !url.startsWith(this.appConfig.authUrl());
  }
}
