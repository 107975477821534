import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { toValidator } from './form/form.helper';
import { DayMonthYear, MonthYear, NoValue } from 'ngx-mobilite-material';

export const strToDayMonthYear = (dateStr: string | NoValue): DayMonthYear => {
  if (dateStr) {
    const date = new Date(dateStr);
    return {
      day: date.getDate().toString().padStart(2, '0'),
      month: (date.getMonth() + 1).toString().padStart(2, '0'),
      year: date.getFullYear().toString()
    };
  } else {
    return {
      day: '',
      month: '',
      year: ''
    };
  }
};

export const strDateToMonthYear = (dateStr: string | NoValue): MonthYear | null => {
  if (dateStr) {
    const date = new Date(dateStr);
    return { month: (date.getMonth() + 1).toString().padStart(2, '0'), year: date.getFullYear().toString() };
  } else {
    return null;
  }
};

export const monthYearToString = (date: MonthYear): string => `${date.year}-${date.month}`;
export const dayMonthYearToString = (date: DayMonthYear): string => `${date.year}-${date.month}-${date.day}`;

export const dayMonthYearToMonthYear = (date: DayMonthYear): MonthYear => ({ year: date.year, month: date.month });

export const dateIsEarlierThanTwoYearsAgo = (date: MonthYear): boolean => {
  return dateIsEarlierThanXYearsAgo(date, 2);
};

export const dateIsThreeYearsAgoOrBefore = (date: MonthYear): boolean => {
  const now = new Date();
  return (
    now.getFullYear() - Number(date.year) > 3 ||
    (now.getFullYear() - Number(date.year) === 3 && now.getMonth() - Number(date.month) >= -1)
  );
};

export const dateIsInTheLast23Months = (date: MonthYear): boolean => {
  const now = new Date();
  return (now.getFullYear() - Number(date.year)) * 12 + (now.getMonth() + 1 - Number(date.month)) <= 23;
};

export const dateIsEarlierThanSixtyFiveYearsAgo = (date: MonthYear): boolean => {
  return dateIsEarlierThanXYearsAgo(date, 65);
};

export const dateIsEarlierThanXYearsAgo = (date: MonthYear, x: number): boolean => {
  const now = new Date();
  return (
    now.getFullYear() - Number(date.year) < x ||
    (now.getFullYear() - Number(date.year) === x && now.getMonth() - Number(date.month) < 0)
  );
};

export const dateIsOlderThanEightyYears = (date: MonthYear): boolean => {
  return dateIsOlderThanXYears(date, 80);
};

export const dateIsOlderThanXYears = (date: MonthYear, x: number): boolean => {
  const now = new Date();
  return (
    now.getFullYear() - Number(date.year) > x ||
    (now.getFullYear() - Number(date.year) === x && now.getMonth() > Number(date.month))
  );
};

export const monthYearValidators = (): ValidatorFn[] => [
  Validators.required,
  toValidator((control: AbstractControl) => (control.value ? control.value.year >= 1900 : false), 'global.dateInPast'),
  toValidator((control: AbstractControl) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDay());
    const date = new Date(now.getFullYear(), now.getMonth());
    if (control.value) {
      date.setMonth(control.value.month - 1);
      date.setFullYear(control.value.year);
    }
    return today >= date;
  }, 'global.dateInFutur')
];

export const moreThanSixteenYearsOldValidator = (birthDate: DayMonthYear | undefined): ValidatorFn => {
  return toValidator(
    ({ value }: AbstractControl) =>
      value &&
      (Number(value.year) - Number(birthDate?.year) > 16 ||
        (Number(value.year) - Number(birthDate?.year) === 16 && Number(value.month) > Number(birthDate?.month))),
    'housing.more-than-sixteen'
  );
};
