<div class="row-description">
  <div>
    {{title}}&nbsp;<ml-icon iconName="question-circle" *ngIf="action" class="inline-icon"></ml-icon>
  </div>
  <div class="column-bold">
    {{value | formatNumber}}&nbsp;{{unit}}
  </div>
</div>

<ng-container *ngFor="let cost of costsTab">
  <div class="row-description little">
    <div>
      {{cost.key | translate}}
    </div>
    <div class="column-bold">
      {{cost.value | formatNumber}}&nbsp;{{unit}}
    </div>
  </div>
</ng-container>
<a (click)="openDetail()" href="#" class="row-link" *ngIf="templateName">
  Voir le détail
</a>
