import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatFormTitle'
})
export class FormatFormTitlePipe implements PipeTransform {
  transform(formName: string): string {
    return formName.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);
  }
}
