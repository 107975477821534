import { AfterViewInit, ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from '@angular/core';
import { StickyService } from '../../sticky.service';
import { InsurancesService } from '../insurances/insurances.service';
import { SimulationService } from './simulation.service';
import { map } from 'rxjs';
import { simulationResultToLoaSimulation, simulationResultToVatSimulation } from '../../helpers/simulation.mapper';

@Component({
  selector: 'fol-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummaryComponent implements AfterViewInit {
  @ViewChild('stickyButtonWrapper', { read: TemplateRef }) stickyButtonWrapper!: TemplateRef<HTMLElement>;
  isVat = false;
  projectSimulation$ = this.simulationService.calculateSimulation().pipe(
    map(simulation => {
      this.isVat = ['VAT', 'VAP'].includes(simulation.product.productCode);
      return this.isVat ? simulationResultToVatSimulation(simulation) : simulationResultToLoaSimulation(simulation);
    })
  );

  backRoute = this.insurancesService.hasWrongASMImmutableInsurances() ? '../insurances' : '../bank-details';
  constructor(
    private readonly stickyService: StickyService,
    private readonly insurancesService: InsurancesService,
    private readonly simulationService: SimulationService
  ) {}

  ngAfterViewInit(): void {
    this.stickyService.setStickyContent(this.stickyButtonWrapper);
  }
}
