import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetFirstPipe } from './get-first.pipe';
import { ControlRegexDirective } from './control-regex.directive';
import { AutoInputDirective } from './auto-input.directive';
import { ToUppercaseDirective } from './to-uppercase.directive';
import { FormatNumberPipe } from './format-number.pipe';
import { FormatFormTitlePipe } from './format-form-title.pipe';

const FORM_UTILS = [
  GetFirstPipe,
  ControlRegexDirective,
  AutoInputDirective,
  ToUppercaseDirective,
  ToUppercaseDirective,
  FormatNumberPipe,
  FormatFormTitlePipe
];

@NgModule({
  declarations: FORM_UTILS,
  exports: FORM_UTILS,
  imports: [CommonModule]
})
export class FormModule {}
