<div class="row center text-center space-top-2" style="font-style:italic">{{ 'application.summary.reminder' | translate }}</div>
<div class="content" *ngIf="projectSimulation$ | async as projectSimulation">
  <div class="card">
    <div class="title">
      <p>{{ "application.summary.offer.euros-per-month-on" | translate:  {price: projectSimulation.rent, duration: projectSimulation.duration, plural : projectSimulation.duration > 1 ? 's' : ''} }}</p>
    </div>
    <div class="subtitle">
      <p>{{ isVat ? ("application.summary.offer.vat" | translate) : ("application.summary.offer.loa" | translate) }}&nbsp;{{"application.summary.offer.with-insurance" | translate}}</p>
    </div>
  </div>
  <ng-container *ngIf="isVat; else loa">
    <ng-container *ngTemplateOutlet="vatSimulation; context: {projectInformation: projectSimulation}"></ng-container>
  </ng-container>
  <ng-template #loa>
    <ng-container *ngTemplateOutlet="loaSimulation; context: {projectInformation: projectSimulation}"></ng-container>
  </ng-template>
</div>
<fol-legal-terms />
<ng-template #stickyButtonWrapper>
  <a mat-flat-button color="secondary" [routerLink]="[backRoute]" id="backButton">
    <mat-icon matPrefix>keyboard_arrow_left</mat-icon>
    {{"application.personal-data.buttons.back" | translate}}
  </a>
  <a mat-flat-button color="primary" [routerLink]="['../user-info-modification']" id="confirmButtom" class="modify-button">
    {{ "application.summary.buttons.confirm" | translate }}
  </a>
</ng-template>

<ng-template #loaSimulation let-projectInformation="projectInformation">
  <div class="configuration">
    <div class="global">
      <fol-detail-row
        [title]="'application.summary.offer.vehicle-price' | translate"
        [value]="projectInformation.vehiclePrice"
        [unit]="'€'"/>
      <fol-detail-row
        [title]="'application.summary.offer.duration' | translate"
        [value]="projectInformation.duration"
        [unit]="'application.summary.offer.years' | translate"/>
      <fol-detail-row
        [title]="'application.summary.offer.annual-mileage' | translate"
        [value]="projectInformation.annualMileage"
        [unit]="'km'"/>
      <fol-detail-row
        [title]="'application.summary.offer.first-installment' | translate"
        [value]="projectInformation.firstInstallment"
        [unit]="'€'"/>
      <fol-detail-row
        [title]="'application.summary.offer.monthly-rent-loa' | translate"
        [value]="projectInformation.rent"
        [unit]="'€'"/>
      <fol-detail-row
        [title]="'application.summary.offer.residual-value' | translate"
        [value]="projectInformation.residualValue"
        [unit]="'€'"/>
    </div>
    <div class="insurances">
      <fol-detail-row *ngIf="projectInformation.borrowerInsurance > 0"
        [title]="'application.summary.offer.borrower-insurance' | translate"
        [value]="projectInformation.borrowerInsurance"
        [unit]="'application.summary.offer.euros-per-month' | translate"/>
      <fol-detail-row *ngIf="projectInformation.purchaseValueWarranty > 0"
        [title]="'application.summary.offer.purchase-value-warranty' | translate"
        [value]="projectInformation.purchaseValueWarranty"
        [unit]="'application.summary.offer.euros-per-month' | translate"/>
      <fol-detail-row *ngIf="projectInformation.extendedWarranty > 0"
        [title]="'application.summary.offer.extended-warranty' | translate"
        [value]="projectInformation.extendedWarranty"
        [unit]="'application.summary.offer.euros-per-month' | translate"/>
    </div>
    <div class="total">
      <fol-detail-row
        [title]="'application.summary.offer.total-price-loa' | translate"
        [value]="projectInformation.totalPrice"
        [unit]="'€'"/>
    </div>
  </div>
</ng-template>

<ng-template #vatSimulation let-projectInformation="projectInformation">
  <div class="configuration">
    <div class="global">
      <fol-detail-row
        [title]="'application.summary.offer.vehicle-price' | translate"
        [value]="projectInformation.vehiclePrice"
        [unit]="'€'"/>
      <fol-detail-row
        [title]="'application.summary.offer.contribution' | translate"
        [value]="projectInformation.contribution"
        [unit]="'€'"/>
      <fol-detail-row
        [title]="'application.summary.offer.monthly-rent-vat' | translate"
        [value]="projectInformation.rent"
        [unit]="'application.summary.offer.euros-per-month' | translate"/>
      <fol-detail-row
        [title]="'application.summary.offer.borrowed-amount' | translate"
        [value]="projectInformation.borrowedAmount"
        [unit]="'€'"/>
      <fol-detail-row
        [title]="'application.summary.offer.duration' | translate"
        [value]="projectInformation.duration"
        [unit]="'application.summary.offer.years' | translate"/>
      <fol-detail-row
        [title]="'application.summary.offer.credit-cost' | translate"
        [value]="projectInformation.creditCost"
        [costsTab]="[{ key: 'application.summary.offer.processing-fees', value: projectInformation.processingFees },
        { key: 'application.summary.offer.interests', value: projectInformation.interestAmount }]"
        [unit]="'€'"/>
      <fol-detail-row
        [title]="'application.summary.offer.taeg' | translate"
        [value]="projectInformation.taeg"
        [unit]="'%'"/>
      <fol-detail-row
        [title]="'application.summary.offer.tnc' | translate"
        [value]="projectInformation.tnc"
        [unit]="'%'"/>
    </div>
    <div class="insurances">
      <fol-detail-row *ngIf="projectInformation.borrowerInsurance > 0"
        [title]="'application.summary.offer.borrower-insurance' | translate"
        [value]="projectInformation.borrowerInsurance"
        [unit]="'application.summary.offer.euros-per-month' | translate"/>
      <fol-detail-row *ngIf="projectInformation.purchaseValueWarranty > 0"
        [title]="'application.summary.offer.purchase-value-warranty' | translate"
        [value]="projectInformation.purchaseValueWarranty"
        [unit]="'application.summary.offer.euros-per-month' | translate"/>
      <fol-detail-row *ngIf="projectInformation.extendedWarranty > 0"
        [title]="'application.summary.offer.extended-warranty' | translate"
        [value]="projectInformation.extendedWarranty"
        [unit]="'application.summary.offer.euros-per-month' | translate"/>
    </div>
    <div class="total">
      <fol-detail-row
        [title]="'application.summary.offer.total-price-vat' | translate"
        [value]="projectInformation.totalPrice"
        [unit]="'€'"/>
    </div>
  </div>
</ng-template>

