import { URLParamValue } from '../helpers/url.helper';

export type Theming = {
  brand: string;
  primaryColor: string;
  secondaryColor: string;
  logoBytes: string;
  logoFileName: string;
  colorPalette: Palette;
  isThemingSet: boolean;
};

export class ThemingQueryParams {
  chainCode: URLParamValue = '';
  unionCode: URLParamValue = '';
  groupCode: URLParamValue = '';
  societyCode: URLParamValue = '';
  pointOfSaleCode: URLParamValue = '';

  constructor(initializer: Partial<ThemingQueryParams> = {}) {
    Object.assign(this, initializer);
  }
  toParamsMap(): Map<string, URLParamValue> {
    const params = new Map<string, URLParamValue>();
    params.set('chain-code', this.chainCode || '');
    params.set('union-code', this.unionCode || '');
    params.set('group-code', this.groupCode || '');
    params.set('society-code', this.societyCode || '');
    params.set('point-of-sale-code', this.pointOfSaleCode || '');
    return params;
  }
}
export type ThemingQueryParamsType = ThemingQueryParams;

/* eslint-disable @typescript-eslint/naming-convention */
export type PaletteColors = Pick<
  Palette,
  50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | 'A100' | 'A200' | 'A400' | 'A700'
>;

export const NOT_COLORS_THEME_KEYS: (keyof Palette)[] = ['useDarkTextColor', 'darkTextColor', 'lightTextColor'];

export type Palette = {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
  A100: string;
  A200: string;
  A400: string;
  A700: string;
  useDarkTextColor: string[];
  darkTextColor: string;
  lightTextColor: string;
};
