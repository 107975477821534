import { NoValue } from '../../core.model';
import { Activity } from '../employment-situation-form/employment-situation-form.model';
import { AccountOwner } from '../bank-details/bank-details.component.model';
import { Project, ToState } from '../application.model';
import { PremisesAndBusiness } from '../company/company.model';

export type Score = {
  decision: DecisionEnum;
  status?: string;
  subStatus?: string;
};

export enum DecisionEnum {
  GREEN = 'V',
  ORANGE = 'O',
  RED = 'R'
}

export enum CivilitySubscription {
  MADAM = 'MME',
  MISTER = 'MR'
}

export enum MaritalStatus {
  SINGLE = 'C',
  MARRIED = 'M',
  CONJUGAL = 'K',
  DIVORCED = 'D',
  WIDOWED = 'V'
}

export enum BankCode {
  BNPA = 1,
  CCBP = 2,
  AGRI = 3,
  PSST = 4,
  CEPA = 5,
  CRLY = 6,
  CMCI = 7,
  SOGE = 8
}

type Context = {
  country: string;
  currency: string;
  channel: string;
  taxExcluded: boolean;
};

type BirthPlace = {
  cityInseeCode: string;
  city: string;
  country: string;
};

type IdentityDocument = {
  nationality: string;
  type: string;
};

export type IdentitySubscription = {
  firstName: string;
  lastName: string;
  maidenName: string;
  title: CivilitySubscription;
  birthDate: string | NoValue;
  birthPlace: BirthPlace;
  identityDocuments: IdentityDocument;
};

export type ContactDataSubscription = {
  mobilePhoneNumber: string | undefined;
  emailAddress: string | undefined;
};

export type HousingDataSubscription = {
  type: string | NoValue;
  seniority: string | NoValue;
};

export type MailingAddressDataSubscription = {
  cityInseeCode: string | NoValue;
  streetName: string | NoValue;
  streetComplement: string | NoValue;
  city?: string;
};

export type EmployerAddressDataSubscription = {
  cityInseeCode: string | NoValue;
  streetName: string | NoValue;
  streetComplement: string | NoValue;
  country: string | NoValue;
  city?: string;
};

export type PersonalDataSubscription = {
  maritalStatus: MaritalStatus;
  nbChildren: string | NoValue;
};

export type BankDetailsDataSubscription = {
  holder: AccountOwner | NoValue;
  levyDay: string | NoValue;
  bankCode: number;
  bic: string | NoValue;
  iban: string | NoValue;
  seniority: string | NoValue;
};

type EmploymentSituationDataWithoutCompanySubscription = {
  contract: string | NoValue;
  profession: string;
  businessSector: Activity;
  seniority: string | NoValue;
  employer: EmployerSubscription | NoValue;
};

export type EmployerSubscription = {
  name: string | NoValue;
  address: EmployerAddressDataSubscription | NoValue;
  contactData: {
    phoneNumber: string | NoValue;
  };
  firstEmployment: boolean;
  previousEmployerDuration: string | NoValue;
  previousEmployerName: string | NoValue;
};

export type HolderEmploymentSituationDataSubscription = {
  borrowerCompany: BorrowerCompany | NoValue;
} & EmploymentSituationDataWithoutCompanySubscription;

export type CoHolderEmploymentSituationDataSubscription = {
  borrowerCompany: BorrowerCompanyCommon | NoValue;
} & EmploymentSituationDataWithoutCompanySubscription;

export type BorrowerCompany = {
  legalForm: string | NoValue;
  businessAssets: string | NoValue;
  creationDate: string | NoValue;
  apeCode: string | NoValue;
  capital: number | string | NoValue;
  accountant: AccountantSubscription | NoValue;
  cityRegistration: string | NoValue;
  nature: string | NoValue;
} & BorrowerCompanyCommon;

export type BorrowerCompanyCommon = {
  identifierValue: string | NoValue;
  employeesNumber: string | NoValue;
  premises: PremisesAndBusiness | NoValue;
  contactData: {
    landlinePhoneNumber: string | NoValue;
  };
};

export type AccountantSubscription = {
  name: string | NoValue;
  address: MailingAddressDataSubscription;
  contactData: {
    phoneNumber?: string;
  };
};

export type PersonalIncomeSubscription = {
  type: string;
  amount: number | string;
};

export type ExpensesSubscription = {
  type: string;
  amount: number | string;
};

export type HolderInformation = {
  status: string;
  identity: IdentitySubscription | NoValue;
  contactData: ContactDataSubscription | NoValue;
  household: HousingDataSubscription | NoValue;
  address: MailingAddressDataSubscription | NoValue;
  personalData: PersonalDataSubscription | NoValue;
  employment: HolderEmploymentSituationDataSubscription | NoValue;
  bankAccount: BankDetailsDataSubscription | NoValue;
  incomes: PersonalIncomeSubscription[] | NoValue;
  expenses: ExpensesSubscription[] | NoValue;
  coBorrower: {
    type: string;
  };
};

export type CoHolderInformation = {
  identity: IdentitySubscription | NoValue;
  contactData: ContactDataSubscription | NoValue;
  address: MailingAddressDataSubscription | NoValue;
  employment: CoHolderEmploymentSituationDataSubscription | NoValue;
};

export type SubmitSubscription = ToState<'context', Context> &
  ToState<'holder', HolderInformation> &
  ToState<'coHolder', CoHolderInformation | null> &
  ToState<'project', Project | null> &
  ToState<'loginId', string> &
  ToState<'webstoreId', string> &
  ToState<'externalId', string>;
