import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config';
import { urlJoin } from '../helpers/url.helper';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  url = '';
  isAuthenticated$ = new BehaviorSubject<boolean>(false);
  constructor(private readonly httpClient: HttpClient, readonly appConfig: AppConfig) {
    if (!appConfig.authApikey()) {
      this.isAuthenticated$.next(true);
    }
    this.url = urlJoin(appConfig.authUrl(), 'internal/token/validate');
  }

  authenticate(initNumber: string, token: string): Observable<boolean> {
    if (!this.appConfig.authApikey()) {
      return this.isAuthenticated$;
    }
    return this.httpClient
      .post<void>(
        this.url,
        { externalId: initNumber, token },
        { observe: 'response', headers: { apikey: this.appConfig.authApikey() } }
      )
      .pipe(
        map(response => {
          const isTokenValid = response.status === 200;
          this.isAuthenticated$.next(isTokenValid);
          return isTokenValid;
        }),
        catchError(() => {
          this.isAuthenticated$.next(false);
          return of(false);
        })
      );
  }
}
