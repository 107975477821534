import { Injectable } from '@angular/core';
import { urlJoin } from '../../helpers/url.helper';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../../app.config';
import { map, Observable } from 'rxjs';
import { ApplicationStore } from '../application-store.service';
import { getBodyForAvailableServices } from '../../helpers/insurances.mapper';
import { AvailableInsurance } from './insurances.model';
import { hasWrongInsurance, INSURANCE_ASM, MAX_PROJECT_COST_FOR_INSURANCE } from '../../helpers/insurance.helper';
import {
  dateIsEarlierThanSixtyFiveYearsAgo,
  dateIsOlderThanEightyYears,
  dayMonthYearToMonthYear
} from '../../helpers/date.helper';
import { DayMonthYear } from 'ngx-mobilite-material';
import { centsToEuros } from '../../helpers/currency.helper';
import { Service } from '../application.model';
import { NoValue } from '../../core.model';

@Injectable({
  providedIn: 'root'
})
export class InsurancesService {
  services = this.applicationStore.instantImmutable('services');
  employmentSituation = this.applicationStore.instant('employmentSituation')?.holder;
  birthdate = this.applicationStore.instant('birthInformation')?.holder?.birthDate;
  isLessThanSixtyFive = dateIsEarlierThanSixtyFiveYearsAgo(dayMonthYearToMonthYear(this.birthdate as DayMonthYear));
  isOlderThanEighty = dateIsOlderThanEightyYears(dayMonthYearToMonthYear(this.birthdate as DayMonthYear));
  isProjectCostMoreThan150000 =
    Number(centsToEuros(this.applicationStore.instant('project')?.vehicle?.price)) > MAX_PROJECT_COST_FOR_INSURANCE;

  private readonly url = urlJoin(this.appConfig.baseWsURL());
  constructor(
    private readonly httpClient: HttpClient,
    private readonly appConfig: AppConfig,
    private readonly applicationStore: ApplicationStore
  ) {}

  getInsurances(category: string): Observable<AvailableInsurance[]> {
    const applicationData = getBodyForAvailableServices(this.applicationStore.value, category);
    return this.httpClient
      .post(`${this.url}/insurances`, applicationData, { observe: 'response' })
      .pipe(map(availableInsurances => availableInsurances.body as AvailableInsurance[]));
  }

  hasWrongASMImmutableInsurances(): boolean {
    return this.hasWrongASMInsurances(this.services);
  }

  hasWrongASMInsurancesInProject(): boolean {
    return this.hasWrongASMInsurances(this.applicationStore.instant('project')?.services);
  }

  private hasWrongASMInsurances(insurances: Service[] | NoValue): boolean {
    return hasWrongInsurance(
      INSURANCE_ASM,
      insurances,
      this.employmentSituation,
      this.isLessThanSixtyFive,
      this.isOlderThanEighty,
      this.isProjectCostMoreThan150000
    );
  }
}
