<form [formGroup]="personalIncomesForm" class="space-top-3">
  <div class="row center">
    <div class="column">
      <fol-form-header type="HOLDER" id="holderFormHeader">
        <ml-currency-input class="holder-name-input" formControlName="holderIncome"
                            [label]='"application.personal-incomes.fields.monthly-income" | translate'
                            [toggleValidationIcon]="true" id="field1"/>
        <p class="under-income">{{"application.personal-incomes.other.under-income" | translate}}</p>
      </fol-form-header>
      <fol-form-header type="CO_HOLDER" *ngIf="userChoice === 'CO_HOLDER'" id="coHolderFormHeader">
        <ml-currency-input formControlName="coHolderIncome"
                            [label]='"application.personal-incomes.fields.monthly-income" | translate'
                            [toggleValidationIcon]="true" id="field2"/>
        <p class="under-income">{{"application.personal-incomes.other.under-income" | translate}}</p>
      </fol-form-header>
      <div *ngIf="(areIncomesFulfilled$ | async)?.value">
        <div class="row center space-top-2"><h1>{{ 'application.personal-incomes.other.question' | translate }}</h1></div>
        <div class="row center text-center space-top-2 space-bot-2">{{ 'application.personal-incomes.other.revenue-type' | translate }}</div>
        <ml-yes-no-input class="select" formControlName="hasOtherIncomes" id="field3"
                         [yesNoTranslations]="yesNoTranslations$ | async"/>
      </div>
      <ng-container formGroupName="otherIncomesGroup" *ngIf="personalIncomesForm.getRawValue().hasOtherIncomes === true">
        <ml-currency-input formControlName="socialBenefits" class="space-bot-2"
                            [label]='"application.personal-incomes.fields.social-benefits" | translate'
                            id="field4"/>
        <ml-currency-input formControlName="otherIncome" class="space-bot-2"
                            [label]='"application.personal-incomes.fields.other-income" | translate'
                            id="field5"/>
      </ng-container>
    </div>
  </div>
</form>
