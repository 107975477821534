import { formatNumber } from '@angular/common';

export const eurosToCents = (value: unknown): string => {
  return Math.round(Number(value || 0) * 100).toString();
};

export const centsToEuros = (value: unknown): string => {
  return (Number(value || 0) / 100).toString();
};

export const formatCurrency = (value: unknown, locale: string): string => {
  return formatNumber(Number(centsToEuros(value)), locale, '.2');
};
