<fol-header/>

<div class='row strap' *ngIf="currentTheming$ | async">
  <div class='item primary background'></div>
  <div class='item secondary background'></div>
</div>

<fol-advancement class="desktop-hide"/>
<div class="separated-container" *ngIf="step$ | async as step">
  <div class="container">
    <div class="content">
      <router-outlet></router-outlet>
    </div>
    <div class='sticky-wrapper' [ngClass]="{ summary: step.isOnSummaryPage}"><div #stickyContainer style='display: none'></div></div>
    <ng-container *ngIf="environmentIsNotProduction()">
      <ng-container *ngTemplateOutlet=brandSelector></ng-container>
    </ng-container>
  </div>
  <!-- div pour la voiture, supprimer le if lorsque l'on aura les entrants-->
  <div class="car" *ngIf="false">
  </div>
</div>

<ng-template #brandSelector>
  <div class='background-dev'>
    <div class='row separator'></div>
    <div class='second form'>
      <div class='column'>
        <div class='item'><label>Partenaire</label></div>
        <div class='item'>
          <mat-form-field>
            <mat-select (selectionChange)='onClick($event.value)'>
              <mat-option [value]='null'>Défaut</mat-option>
              <mat-option *ngFor='let partner of config | keyvalue' [value]='partner.key'>{{ partner.key }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class='row separator last'></div>

    <div class='row'>
      <button mat-flat-button> Basic </button>
      <button mat-flat-button color='primary'> Primary </button>
      <button mat-flat-button color='accent'> Accent </button>
      <button mat-flat-button color='warn'> Warn </button>
      <button mat-flat-button disabled>Disabled</button>
    </div>

    <div class='row'>
      <button mat-stroked-button> Basic </button>
      <button mat-stroked-button color='primary'> Primary </button>
      <button mat-stroked-button color='accent'> Accent </button>
      <button mat-stroked-button color='warn'> Warn </button>
      <button mat-stroked-button disabled>Disabled</button>
    </div>

    <div class='row'>
      {{ packageVersion }}
    </div>
  </div>
</ng-template>
