import {
  ApplicationDataState,
  BaseSimulation,
  LoaSimulation,
  Service,
  VatSimulation
} from '../application/application.model';
import { BoxAutoInputDTO, InsuranceFilter, SimulationResult } from '../application/insurances/insurances.model';
import { getBoxAutoFromProject } from './box-auto.mapper';

export const getBoxAutoInputFromProject = (applicationData: ApplicationDataState): BoxAutoInputDTO => {
  return {
    ...getBoxAutoFromProject(applicationData),
    insuranceFilterList: applicationData.project?.services?.length
      ? toInsuranceFilterList(applicationData.project?.services)
      : []
  };
};

const toInsuranceFilterList = (insurances: Service[]): InsuranceFilter[] => {
  return insurances.map(insurance => ({ category: insurance.category, option: insurance.option } as InsuranceFilter));
};

const simulationResultToBaseSimulation = (simulation: SimulationResult): BaseSimulation => {
  return {
    rent: String(simulation.product.instalmentAmount),
    duration: Math.round(Number(simulation.product.duration) / 12),
    vehiclePrice: String(simulation.vehicle.vehicleAmount),
    borrowerInsurance: String(
      simulation.product.insurances.find(insurance => insurance.category === 'ASM')?.instalmentAmount || 0
    ),
    totalPrice: String(simulation.product.totalAmount),
    purchaseValueWarranty: String(
      simulation.product.insurances.find(insurance => insurance.category === 'PFI')?.instalmentAmount || 0
    ),
    extendedWarranty: String(
      simulation.product.insurances.find(insurance => insurance.category === 'PACK')?.instalmentAmount || 0
    )
  };
};

export const simulationResultToVatSimulation = (simulation: SimulationResult): VatSimulation => {
  return {
    ...simulationResultToBaseSimulation(simulation),
    taeg: String(simulation.product.taeg),
    tnc: String(simulation.product.pricingDetail.tnc),
    processingFees: String(simulation.product.applicationFeesAmount),
    interestAmount: String(simulation.product.interestAmount),
    contribution: String(simulation.product.depositAmount),
    borrowedAmount: String(simulation.product.overdraftAmount),
    creditCost: String(simulation.product.interestAmount + simulation.product.applicationFeesAmount)
  };
};

export const simulationResultToLoaSimulation = (simulation: SimulationResult): LoaSimulation => {
  return {
    ...simulationResultToBaseSimulation(simulation),
    annualMileage: String(simulation.product.annualMileage),
    residualValue: String(simulation.product.residualValueAmount),
    firstInstallment: String(simulation.product.firstInstalmentAmount)
  };
};
