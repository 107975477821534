import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import '@design-system/base-icon';
import { KeyValue } from 'ngx-mobilite-material';

@Component({
  selector: 'fol-detail-row',
  templateUrl: './detail-row.component.html',
  styleUrls: ['./detail-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailRowComponent {
  @Input()
  title = '';
  @Input()
  value: number | string = 0;
  @Input()
  unit = '';
  @Input()
  action = false;
  @Input()
  templateName = '';
  @Output()
  templateNameEvent = new EventEmitter<string>();
  @Input()
  costsTab: KeyValue[] = [];

  openDetail(): void {
    this.templateNameEvent.emit(this.templateName);
  }
}
