import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { urlBuildWithParams, urlJoin } from '../helpers/url.helper';
import { NOT_COLORS_THEME_KEYS, Palette, PaletteColors, Theming, ThemingQueryParamsType } from './theming.model';
import { BehaviorSubject, Observable, takeUntil, tap } from 'rxjs';
import { TakeUntil } from '../helpers/take-until.component';
import { AppConfig } from '../../app.config';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class ThemingService extends TakeUntil {
  private readonly url = urlJoin(this.appConfig.baseWsURL(), 'partners/theme');
  private readonly EMPTY_THEMING: Theming = {
    brand: '',
    logoBytes: '',
    primaryColor: '',
    secondaryColor: '',
    logoFileName: '',
    isThemingSet: false,
    colorPalette: {} as Palette
  };
  private readonly currentTheme = new BehaviorSubject<Theming>(this.EMPTY_THEMING);

  constructor(
    private readonly httpClient: HttpClient,
    private readonly appConfig: AppConfig,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer
  ) {
    super();
    this.matIconRegistry.addSvgIcon(
      'fol-edit',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/material-icon/svg/fol-edit.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'fol-check',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/material-icon/svg/fol-check.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'fol-biberon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/material-icon/svg/biberon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'fol-localisation',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/material-icon/svg/localisation.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'fol-tirelire',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/material-icon/svg/tirelire.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'fol-death',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/material-icon/svg/fol-death.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'fol-invalid',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/material-icon/svg/fol-invalid.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'fol-invalid-excluded',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/material-icon/svg/fol-invalid-excluded.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'fol-job',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/material-icon/svg/fol-job.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'fol-job-excluded',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/material-icon/svg/fol-job-excluded.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'fol-accident',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/material-icon/svg/fol-accident.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'fol-accident-excluded',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/material-icon/svg/fol-accident-excluded.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'fol-check2',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/material-icon/svg/fol-check2.svg')
    );

    this.getCurrentTheming()
      .pipe(takeUntil(this.destroy))
      .subscribe((theming: Theming) => {
        document.documentElement.style.setProperty('--fol-primary-color', theming.primaryColor);
        document.documentElement.style.setProperty('--fol-secondary-color', theming.secondaryColor);

        if (theming.colorPalette) {
          document.documentElement.style.setProperty('--theme-dark-text-color', theming.colorPalette.darkTextColor);
          document.documentElement.style.setProperty('--theme-light-text-color', theming.colorPalette.lightTextColor);
          Object.keys(theming.colorPalette)
            .filter(key => !(NOT_COLORS_THEME_KEYS as string[]).includes(key))
            .forEach(key => {
              const key1 = `--ml-theme-primary-${key}`;
              const value1 = theming.colorPalette[key as keyof PaletteColors];
              const key2 = `--ml-theme-primary-contrast-${key}`;
              const value2 = theming.colorPalette.useDarkTextColor.includes(key)
                ? theming.colorPalette.darkTextColor
                : theming.colorPalette.lightTextColor;
              document.documentElement.style.setProperty(key1, value1);
              document.documentElement.style.setProperty(key2, value2);
              if (key === '100') {
                document.documentElement.style.setProperty('--ml-theme-primary-border', value1 + '99');
              }
            });
        }
      });
  }

  getThemingJson(themingQuery: ThemingQueryParamsType): Observable<Theming> {
    return this.httpClient
      .get<Theming>(urlBuildWithParams(this.url, themingQuery.toParamsMap()))
      .pipe(tap(theming => this.setCurrentTheming(theming)));
  }

  setCurrentTheming(theming: Theming): void {
    theming.isThemingSet = true;
    this.currentTheme.next(theming);
  }

  getCurrentTheming(): Observable<Theming> {
    return this.currentTheme.asObservable();
  }
}
