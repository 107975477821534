import { Injectable } from '@angular/core';
import { urlJoin } from '../../helpers/url.helper';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../../app.config';
import { map, Observable } from 'rxjs';
import { ApplicationStore } from '../application-store.service';
import { SimulationResult } from '../insurances/insurances.model';
import { getBoxAutoInputFromProject } from '../../helpers/simulation.mapper';

@Injectable({
  providedIn: 'root'
})
export class SimulationService {
  private readonly url = urlJoin(this.appConfig.baseWsURL());
  constructor(
    private readonly httpClient: HttpClient,
    private readonly appConfig: AppConfig,
    private readonly applicationStore: ApplicationStore
  ) {}

  calculateSimulation(): Observable<SimulationResult> {
    const applicationData = getBoxAutoInputFromProject(this.applicationStore.value);
    return this.httpClient
      .post(`${this.url}/simulation`, applicationData, { observe: 'response' })
      .pipe(map(availableInsurances => availableInsurances.body as SimulationResult));
  }
}
