<div class="breadcrumb" *ngIf="isBreadcrumbShown">
  <ml-icon iconName="people" [ngClass]="{ 'theming':  breadcrumbAdvancementName ==='people' }"></ml-icon>
  <ml-icon iconName="dash"></ml-icon>
  <ml-icon iconName="biberon" [ngClass]="{'theming': breadcrumbAdvancementName === 'fol-biberon' }"></ml-icon>
  <ml-icon iconName="dash"></ml-icon>
  <ml-icon iconName="localisation" [ngClass]="{'theming': breadcrumbAdvancementName === 'fol-localisation' }"></ml-icon>
  <ml-icon iconName="dash"></ml-icon>
  <ml-icon iconName="family" [ngClass]="{'theming': breadcrumbAdvancementName === 'familly' }"></ml-icon>
  <ml-icon iconName="dash"></ml-icon>
  <ml-icon iconName="work" [ngClass]="{'theming': breadcrumbAdvancementName === 'work' }"></ml-icon>
  <ml-icon iconName="dash"></ml-icon>
  <ml-icon iconName="tirelire" [ngClass]="{'theming': breadcrumbAdvancementName === 'fol-tirelire' }"></ml-icon>
  <ml-icon iconName="dash"></ml-icon>
  <ml-icon iconName="wallet" [ngClass]="{'theming': breadcrumbAdvancementName === 'wallet' }"></ml-icon>
  <ml-icon iconName="dash"></ml-icon>
  <ml-icon iconName="bank" [ngClass]="{'theming': breadcrumbAdvancementName === 'bank' }"></ml-icon>
</div>
