import { ToForm } from '../../helpers/form/form.helper';
import { FormGroup } from '@angular/forms';
import { MonthYear } from 'ngx-mobilite-material';

export type BicIban = {
  bic: string;
  iban: string;
};

export type BankDetails = BicIban & {
  isJointAccount?: boolean;
  openedSince?: MonthYear;
  accountOwner?: AccountOwner;
  debitingDate?: DebitingDate;
};

export enum DebitingDate {
  'FIVE' = '5',
  'TWENTY' = '20'
}

export enum AccountOwner {
  'JOINT_ACCOUNT' = 'J',
  'ALONE' = '1',
  'CO_HOLDER' = '2'
}

type AccountOwnerField = ToForm<Pick<BankDetails, 'accountOwner'>>;
export type BicIbanFormGroup = ToForm<Pick<BankDetails, 'bic' | 'iban'>>;
export type BankDetailsForm = ToForm<Pick<BankDetails, 'isJointAccount' | 'openedSince' | 'debitingDate'>> & {
  bicIbanGroup: FormGroup<BicIbanFormGroup>;
} & Partial<AccountOwnerField>;

type BicIbanGroup = Pick<BankDetails, 'bic' | 'iban'>;
export type BankDetailsFormData = Pick<BankDetails, 'isJointAccount' | 'openedSince' | 'debitingDate'> & {
  bicIbanGroup?: BicIbanGroup;
} & { accountOwnerField: AccountOwner };
