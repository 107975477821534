import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config';
import { urlJoin } from '../helpers/url.helper';
import {
  LegalNoticeInsurancesRequest,
  LegalNoticeProductRequest,
  LegalNoticeResponse
} from './legal-notice.model.component';
import { Observable, ReplaySubject, switchMap, takeUntil, tap } from 'rxjs';
import { ApplicationStore } from '../application/application-store.service';
import {
  projectToLegalNoticeInsurancesRequest,
  projectToLegalNoticeProductRequest
} from './legal-notice.mapper.component';
import { Project } from '../application/application.model';
import { TakeUntil } from 'ngx-mobilite-material';

@Injectable({
  providedIn: 'root'
})
export class LegalNoticeService extends TakeUntil {
  legalNoticeContent$ = new ReplaySubject<string>(1);
  updateML = new EventEmitter<string>();

  private readonly url = urlJoin(this.appConfig.baseWsURL(), 'legal-notice');
  private type = '';
  constructor(
    private readonly appConfig: AppConfig,
    private readonly applicationStore: ApplicationStore,
    protected readonly httpClient: HttpClient
  ) {
    super();
    this.initMentionsLegales().pipe(takeUntil(this.destroy)).subscribe();
  }

  public initMentionsLegales(): Observable<LegalNoticeResponse> {
    return this.updateML.pipe(
      switchMap(type => {
        const project = this.applicationStore.instant('project') as Project;
        return type === 'product'
          ? this.fetchLegalNoticeProduct(projectToLegalNoticeProductRequest(project))
          : this.fetchLegalNoticeInsurances(
              projectToLegalNoticeInsurancesRequest(project.product.code || 'C', 'ASM', type)
            );
      }),
      tap(legalNotice => this.legalNoticeContent$.next(legalNotice.content))
    );
  }

  public setType(type: string): void {
    if (type !== this.type) {
      this.type = type;
      this.updateML.next(type);
    }
  }

  fetchLegalNoticeProduct(legalNoticeProductRequest: LegalNoticeProductRequest): Observable<LegalNoticeResponse> {
    return this.httpClient.post<LegalNoticeResponse>(`${this.url}/product`, legalNoticeProductRequest);
  }

  fetchLegalNoticeInsurances(
    legalNoticeInsurancesRequest: LegalNoticeInsurancesRequest
  ): Observable<LegalNoticeResponse> {
    return this.httpClient.post<LegalNoticeResponse>(`${this.url}/insurances`, legalNoticeInsurancesRequest);
  }
}
