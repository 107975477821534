<div class="container">
  <div class="spinner">
    <mat-progress-spinner [color]="'primary'" [mode]="'indeterminate'" class="spinner-style" style="width:4rem; height: 4rem;"></mat-progress-spinner>
  </div>
  <div class="text">
    <div class="title">
      {{ title }}
    </div>
    <div class="subtitle">
      {{ subtitle }}
    </div>
  </div>
</div>
