import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { ImgComponent } from './atom/img/img.component';
import { SharedModule } from '../shared/shared.module';
import { FormModule } from '../core/helpers/form/form.module';
import { StepComponent } from './atom/step/step.component';
import { AdvancementComponent } from './advancement/advancement.component';
import { FormHeaderComponent } from './atom/form-header/form-header.component';
import { BreadcrumbTrailComponent } from './atom/breadcrumb-trail/breadcrumb-trail.component';
import { DetailRowComponent } from './atom/detail-row/detail-row.component';
import { IconComponent } from 'ngx-mobilite-material';

@NgModule({
  declarations: [
    HeaderComponent,
    ImgComponent,
    StepComponent,
    AdvancementComponent,
    FormHeaderComponent,
    BreadcrumbTrailComponent,
    DetailRowComponent
  ],
  exports: [
    HeaderComponent,
    StepComponent,
    AdvancementComponent,
    FormHeaderComponent,
    BreadcrumbTrailComponent,
    DetailRowComponent
  ],
  imports: [CommonModule, SharedModule, FormModule, IconComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class GraphicModule {}
