import { ApplicationDataState } from '../application/application.model';
import { FetchInsuranceInput } from '../application/insurances/insurances.model';
import { EmployeeFormData } from '../application/employment-situation-form/employee-form/employee-form-model';
import { dayMonthYearToString, strToDayMonthYear } from './date.helper';
import { getBoxAutoFromProject } from './box-auto.mapper';

export const getBodyForAvailableServices = (
  applicationData: ApplicationDataState,
  category: string
): FetchInsuranceInput => {
  const today = strToDayMonthYear(new Date().toString());
  return {
    boxAutoInputDTO: {
      ...getBoxAutoFromProject(applicationData),
      insuranceFilterList: [{ category }]
    },
    filteringInfo: {
      activity: applicationData.employmentSituation?.holder?.activity,
      contractCode: applicationData.employmentSituation?.holder?.contract
        ? (applicationData.employmentSituation?.holder?.contract as EmployeeFormData).contractType.key
        : null,
      birthDate: dayMonthYearToString(applicationData.birthInformation?.holder?.birthDate || today),
      totalAmountWithoutInsurance: applicationData.project?.vehicle?.price
    }
  };
};
