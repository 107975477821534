import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ThemingService } from '../../core/theming/theming.service';
import { Base64, EMPTY_IMG, ImgExt } from '../atom/img/img.model';
import { toBase64 } from '../atom/img/img.utils';

@Component({
  selector: 'fol-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  src: Base64<ImgExt> = EMPTY_IMG;
  brandTitle = '';
  isThemingSet = false;

  brandImageName = '';

  constructor(private readonly themingService: ThemingService, private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.themingService.getCurrentTheming().subscribe(theming => {
      this.src = toBase64(theming.logoFileName, theming.logoBytes);
      this.brandTitle = theming.brand;
      this.isThemingSet = theming.isThemingSet;
      this.brandImageName = [theming.brand, 'logo'].join(' ');
      this.cdr.markForCheck();
    });
  }
}
