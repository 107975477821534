import { Injectable, InjectionToken } from '@angular/core';

export type Config = {
  url: string;
  apikey: string;
  auth: {
    url: string;
    apikey: string;
  };
};

export const VERSION_TOKEN = new InjectionToken<string>('VERSION_TOKEN');

@Injectable()
export abstract class AppConfig {
  abstract baseWsURL: () => string;
  abstract apikey: () => string;
  abstract authUrl: () => string;
  abstract authApikey: () => string;
}
